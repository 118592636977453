<!--
 * @Author: lbh
 * @Date: 2022-09-20 17:04:39
 * @LastEditors: lbh
 * @LastEditTime: 2023-07-13 10:39:19
 * @Description: file content
-->
<template>
  <div class="px-edit-can-doing-box">
    <div class="cell-b">
      <h3>外-上下邊距</h3>
      <el-slider
        v-model="configs.topPadding"
        :min="0"
        :max="500"
        show-input
        @input="topPaddingInput"
      >
      </el-slider>
    </div>
    <div class="cell-b">
      <h3>外-左右邊距</h3>
      <el-slider
        v-model="configs.padding"
        :min="0"
        :max="500"
        show-input
        @input="paddingInput"
      >
      </el-slider>
    </div>
    <div class="cell-b">
      <h3>內邊距</h3>
      <el-slider
        v-model="configs.nPadding"
        :min="0"
        :max="500"
        show-input
        @input="nPaddingInput"
      >
      </el-slider>
    </div>
    <!-- 上傳圖片 -->
    <div class="cell-b">
      <h3>選擇圖片</h3>
      <selfUpload
        v-model="configs.image"
        type="default"
        @change="setValue('image')"
      />
      <el-input
        v-model="configs.alt"
        type="textarea"
        @change="setValue('alt')"
        placeholder="請輸入圖片描述"
        rows="3"
      />
    </div>
    <div class="cell-b">
      <h3>更改標題</h3>
      <div class="cell-b">
        <el-input
          v-for="lItem in language"
          :key="lItem.code"
          v-model="configs[`${lItem.code=='HK'?'':lItem.code}title`]"
          :placeholder="`請輸入標題-${lItem.name}`"
          @input="setValue(`${lItem.code=='HK'?'':lItem.code}title`)"
          type="textarea"
          rows="3"
        />
      </div>

    </div>
    <div class="cell-b ">
      <h3>更改副標題</h3>
      <div class="title-b ">
        <div class="cell-b">
          <el-input
            v-for="lItem in language"
            :key="lItem.code"
            v-model="configs.subTitle[`${lItem.code=='HK'?'':lItem.code}text`]"
            :placeholder="`請輸入標題-${lItem.name}`"
            @input="setValue('subTitle')"
            type="textarea"
            rows="3"
          />
        </div>
        <el-color-picker
          v-model="configs.subTitle.color"
          @change="onColorChange()"
        ></el-color-picker>
      </div>
    </div>
    <div class="cell-b ">
      <h3>按鈕1</h3>
      <div class="title-b">
        <div style="width:50%">
          <el-input
            v-for="lItem in language"
            :key="lItem.code"
            v-model="configs.btn1[`${lItem.code=='HK'?'':lItem.code}title`]"
            @input="setValue('btn1')"
            :placeholder="`請輸入-${lItem.name}`"
            type="textarea"
            rows="3"
          />
        </div>
        <selfPagePicker
          v-model="configs.btn1.go"
          @onChange="setValue('btn1')"
        />
      </div>
    </div>
    <div class="cell-b ">
      <h3>按鈕2</h3>
      <div class="title-b">
        <div style="width:50%">
          <el-input
            v-for="lItem in language"
            :key="lItem.code"
            v-model="configs.btn2[`${lItem.code=='HK'?'':lItem.code}title`]"
            :placeholder="`請輸入-${lItem.name}`"
            @input="setValue('btn2')"
            type="textarea"
            rows="3"
          />
        </div>
        <selfPagePicker
          v-model="configs.btn2.go"
          @onChange="setValue('btn2')"
        />
      </div>
    </div>
    <div class="cell-b">
      <h3>描述
        <el-tooltip
          v-if="configs.arr.length == 0"
          class="item"
          effect="dark"
          content="新增一項"
          placement="bottom"
        >
          <el-button
            icon="el-icon-plus"
            circle
            size="mini"
            style="margin-left:10px;"
            @click="addDom(0)"
          ></el-button>
        </el-tooltip>

      </h3>
      <div
        v-for="(item,index) in configs.arr"
        :key="index"
        class="title-b"
        style="margin-bottom:10px;"
      >
        <div class="cell-b">
          <el-input
            v-for="lItem in language"
            :key="lItem.code"
            v-model="item[`${lItem.code=='HK'?'':lItem.code}title`]"
            :placeholder="`請輸入-${lItem.name}`"
            @input="setValue('arr')"
            type="textarea"
            rows="3"
          />
        </div>

        <div class="right">
          <el-tooltip
            class="item"
            effect="dark"
            content="向上添加"
            placement="bottom"
          >
            <el-button
              icon="el-icon-top"
              circle
              size="mini"
              style="margin-left:10px;"
              @click="addDom(index)"
            ></el-button>
          </el-tooltip>
          <el-tooltip
            class="item"
            effect="dark"
            content="向下添加"
            placement="bottom"
          >
            <el-button
              icon="el-icon-bottom"
              circle
              size="mini"
              @click="addDom(index+1)"
            ></el-button>
          </el-tooltip>
          <el-tooltip
            class="item"
            effect="dark"
            content="刪除"
            placement="bottom"
          >
            <el-button
              icon="el-icon-delete"
              circle
              size="mini"
              @click="delDom(index)"
            ></el-button>
          </el-tooltip>
        </div>
      </div>

    </div>
    <div class="cell-b">
      <h3>選擇移動端替換圖片</h3>
      <selfUpload
        v-model="configs.phoneImage"
        type="default"
        @change="setValue('phoneImage')"
      />
      <div class="cell-b">
        <h4>移動端替換圖片點擊</h4>
        <selfPagePicker
          v-model="configs.phoneImageGo"
          @onChange="setValue('phoneImageGo')"
        />
      </div>
    </div>

  </div>
</template>

<script>
import langEditMixin from '../../mixins/langEdit'
import header from '../header.vue';
export default {
  components: { header },
  name: "edit-can-doing",
  mixins: [langEditMixin],
  props: {
    type: {
      default () {
        return 'title';
      },
    },
    configs: {
      default () {
        return {
          image: '',
          title: '',
          subTitle: {
            text: '',
            color: '',
          },
          btn1: {
            title: '',
            go: '',
          },
          btn2: {
            title: '',
            go: '',
          },
          arr: [{ title: '' }],
        }
      }
    },
    index: {
      default () {
        return 0;
      },
    },
  },
  methods: {
    setValue (key) {
      this.$emit('setValue', { key: key, value: this.configs[key] })
    },
    topPaddingInput () {
      this.setValue('topPadding')

    },
    paddingInput () {
      this.setValue('padding')
    },
    nPaddingInput () {
      this.setValue('nPadding')

    },
    onColorChange () {
      this.setValue('subTitle')
    },
    addDom (index) {
      this.configs.arr.splice(index, 0, { title: "" });
      this.setValue('arr')
    },
    delDom (index) {
      this.configs.arr.splice(index, 1)
      this.setValue('arr')
    }
  }
}
</script>

<style lang="less" scoped>
.px-edit-can-doing-box {
  .cell-b {
    box-shadow: 0 2px 6px #ccc;
    padding: 10px;
    border-radius: 4px;
    margin-bottom: 10px;
  }
  .title-b {
    display: flex;
    align-items: center;
  }
}
</style>